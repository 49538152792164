import {
  AppStoreDownload,
  APP_STORE_LINK,
  Button,
  Button2,
  GooglePlayDownload,
  GOOGLE_STORE_LINK,
  ImageKitImage,
  Link2,
  MapPinAlt,
  MiloGuides,
  MiloMag,
  MiloMap,
  MiloPass,
  ReactFC,
  SideScroller,
  Modal,
  ModalBody,
  HowToUseContent,
} from '@milo/react';
import { Experience, Guide } from '@milo/types';
import { default as classNames, default as cn } from 'classnames';
import { useEffect, useState } from 'react';

const headerTextSize = cn('text-4xl');
const baseTextSize = cn('text-xl');

const Section: ReactFC<{ bgDarker?: boolean; bottomMargin?: boolean }> = ({
  className,
  children,
  bgDarker = false,
  bottomMargin = true,
}) => {
  return (
    <section className={cn(className, 'py-10 md:rounded-3xl', { 'bg-gray-lighter': bgDarker, 'mb-10': bottomMargin })}>
      {children}
    </section>
  );
};

export const HomePage = ({ experiences = [], guides = [] }: { guides: Guide[]; experiences: Experience[] }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-screen overflow-x-hidden">
      <div className="web-container pt-10 text-xl">
        <div className="grid gap-6 text-center md:grid-cols-2 md:grid-rows-1 md:text-left">
          <div>
            <div className="max-h-96 overflow-hidden rounded-xl shadow-xl md:hidden">
              <img src="/images/home/mtaregion/sirop.jpg" className="w-full rounded-xl object-cover" />
            </div>
            <div className="relative hidden w-full flex-1 self-start md:block md:h-[min(60vh,560px)]">
              <div className="absolute left-0 z-10 mx-auto aspect-square w-2/3 max-w-[300px] rounded-xl shadow-xl">
                <img src="/images/home/mtaregion/sirop.jpg" className="h-full w-full rounded-xl object-cover" />
                <div className="absolute left-3/4 top-1/2 z-20 aspect-square w-4/5 rounded-xl shadow-xl">
                  <img src="/images/home/mtaregion/rafting.jpg" className="h-full w-full rounded-xl object-cover" />
                  <div className="absolute right-3/4 top-2/3 z-30 aspect-square w-full rounded-xl shadow-xl">
                    <img src="/images/home/mtaregion/picnic.jpg" className="h-full w-full rounded-xl object-cover" />
                  </div>
                </div>
              </div>
              <div className="overflow-hidden after:clear-both after:table after:content-['']"></div>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-6">
            <img
              className="mx-auto hidden w-full md:block"
              src="/images/home/mtaregion/mtaregion-decouvrez.png"
              alt="M ta région. Découvrez, profitez, économisez."
            />
            <h1 className={`${headerTextSize} font-bold`}>
              La plateforme pour tous les <span className="text-red">amoureux du Québec</span>
            </h1>
            <p className={classNames(baseTextSize)}>
              Notre mission ? <span className="font-bold text-red">Soutenir l'économie locale</span> en mettant de
              l'avant des expériences québécoises et vous inspirer pour vos sorties tout en vous permettant de{' '}
              <span className="font-bold text-red">faire plus d'économies</span>
            </p>
            <div className="flex flex-col gap-5 mt-5 md:gap-5 md:flex-row">
              <Link2 href="/about">
                <Button className="mx-auto w-fit md:mx-0">Notre histoire</Button>
              </Link2>

              <Button
                variant="secondary"
                className="mx-auto w-fit md:mx-0"
                onClick={() => setOpen(!open)}
              >
                Comment ça marche ?
              </Button>

              <Modal open={open} onRequestClose={() => setOpen(!open)}>
                <ModalBody className="!max-h-[550px]">
                  <HowToUseContent />
                </ModalBody>
              </Modal>
            </div>
          </div>
        </div>

        <Section>
          <div className="relative grid animate-fade-in-top gap-8 py-10 text-center md:grid-cols-2 md:grid-rows-1 md:text-left">
            <div className="order-2 flex flex-col justify-center gap-6 md:order-none">
              <h2 className={`${headerTextSize} font-bold`}>
                Des expériences locales <span className="text-red">pour tous les goûts</span>
              </h2>
              <p className={classNames(baseTextSize)}>
                Un catalogue de près de 400 adresses vérifiées par notre équipe. Spas, restaurants, microbrasseries,
                hôtels, chalets, parc régionaux, boutiques d'artisans locaux et bien plus encore!
              </p>
              <Link2 href="/experiences">
                <Button className="mx-auto w-fit md:mx-0">Explorez le catalogue</Button>
              </Link2>
            </div>
            <img
              src="/images/home/mtaregion/verticals.png"
              alt="Carte M ta région"
              className="mx-auto w-full max-w-xl"
            />
            <div className="absolute -left-[50vw] -right-[50vw] bottom-0 top-0 -z-loading bg-[#f8f8f8]" />
          </div>
        </Section>

        <Section>
          <h2 className={`${headerTextSize} text-center font-bold`}>
            Profitez des meilleures expériences locales <span className="text-red">au meilleur prix !</span>
          </h2>

          <div className="relative grid animate-fade-in-top gap-6 py-10 md:grid-cols-2 md:grid-rows-1">
            <img
              src="/images/home/mtaregion/phone-showcase.png"
              alt="Application M ta région"
              className="mx-auto w-full max-w-md"
            />
            <div className={`${baseTextSize} flex flex-col  justify-center gap-6 text-center md:text-left`}>
              <MiloPass className="h-9 md:mr-auto" />
              <p>Aujourd’hui, sortir et consommer peut vite coûter cher.</p>
              <p>Pourtant il est important de profiter de moments privilégiés en famille ou entre amis.</p>
              <p>Le pass M ta Région vous permet de continuer à vous faire plaisir tout en faisant plus d‘économies!</p>
              <Link2 href="/purchase">
                <Button className="mx-auto w-fit md:mx-0">Je veux mon pass !</Button>
              </Link2>
              <ProfitableWordRotation />
            </div>
          </div>
        </Section>

        <Section>
          <div className="flex flex-col items-center gap-8 rounded-2xl px-8 py-4 md:flex-row">
            <img
              className="relative order-3 w-52 md:order-1"
              src="/images/home/phone-array.png"
              alt="Milo sur toutes les plateformes"
            />

            <h2 className="order-1 basis-3/5 text-center text-4xl font-bold md:order-2">
              Bonifiez votre expérience.
              <br />
              Téléchargez l'app M ta région.
            </h2>

            <div className="order-2 flex w-44 flex-col gap-2 md:order-3">
              <Link2 href={APP_STORE_LINK}>
                <AppStoreDownload className="w-full" />
              </Link2>
              <Link2 href={GOOGLE_STORE_LINK}>
                <GooglePlayDownload className="w-full" />
              </Link2>
            </div>
          </div>
        </Section>

        <Section className="text-center">
          <MiloGuides className="mx-auto mb-6 h-9" />

          <p className="mb-10 text-2xl">Consultez nos 150+ guides découvertes pour inspirer vos prochaines sorties.</p>

          {/* Static guides for now */}
          <SideScroller className="mb-10">
            {guides.map((guide) => {
              const saving = (guide?.experiences || []).reduce((acc, exp) => acc + (exp?.savings_max || 0), 0) / 100;
              const roundToClosestFive = (num: number) => Math.round(num / 5) * 5;
              const savingsAmount = roundToClosestFive(saving);

              return (
                <Link2 href={`/guides/${guide.slug}`} key={guide.slug} data-testid="homepage-guides-link">
                  <div className="relative h-[200px] w-screen max-w-[350px] overflow-hidden rounded-xl text-left">
                    <ImageKitImage
                      imageFileName={guide?.experiences?.[0]?.main_image as string}
                      className="absolute left-0 top-0 h-full w-full object-cover"
                      transforms="w-350,h-200,q-50"
                      alt=""
                    />
                    <div className="absolute bottom-0 left-0 right-0 top-0 bg-black/25 px-4 pt-6 font-bold text-white">
                      <p className="mb-4 text-2xl">-{savingsAmount}$</p>
                      <p className="mb-4 text-lg">{guide.title}</p>
                      <div className="flex w-fit items-center gap-2 rounded-full bg-gray-light/20 px-2 py-1 text-white">
                        <MapPinAlt className="w-3" />
                        {guide?.region?.name}
                      </div>
                    </div>
                  </div>
                </Link2>
              );
            })}
          </SideScroller>

          <Link2 href="/guides">
            <Button>Explorez tous les guides</Button>
          </Link2>
        </Section>

        <Section>
          <MiloMap className="mx-auto mb-6 h-9" />

          <p className="mb-4 text-center text-2xl">
            Explorez les environs partout au Québec grâce à notre carte géolocalisée.
          </p>
          <div
            className="relative mx-auto flex h-screen max-h-[350px] items-center overflow-hidden p-[2vw] md:max-h-[450px] md:rounded-3xl"
            style={{
              background: `linear-gradient(90deg,rgba(255,255,255,1) 45%, rgba(0,0,0,0) 100%)`,
            }}
          >
            <div className="max-w-xs">
              <h2 className="mb-6 text-3xl font-semibold">Des expériences locales pour tous les goûts</h2>

              <p className="mb-6">Découvrez les expériences M ta région et créez vos itinéraires personnalisés.</p>

              <Link2 href="/map">
                <Button>Voir la carte</Button>
              </Link2>
            </div>
            <img
              src="/images/home/map-showcase.png"
              className="absolute right-0 -z-10 h-full object-cover"
              alt="Carte M ta région"
            />
          </div>
        </Section>

        <Section className="text-center">
          <MiloMag className="mx-auto mb-6 h-9" />

          <p className="mb-10 text-2xl">
            Trouvez les meilleures astuces et actualités pour partir à la découverte du Québec.
          </p>

          <SideScroller className="mb-6">
            <Link2 href="/mag-mtaregion/entrevue-rencontrez-vicky-de-la-belle-excuse">
              <div className="relative mx-2 mb-4 min-h-[250px] max-w-[300px] overflow-hidden rounded-xl text-left shadow-lg">
                <img
                  src={`/wp-content/uploads/2022/10/311586969_5616748038371749_7515329476885463724_n.jpeg`}
                  className="w-full"
                  alt="Mag M ta region: Vicky de la belle excuse"
                />
                <div className="absolute bottom-0 min-h-[80px] w-full bg-white p-3">
                  <p className="mb-2 truncate text-lg font-semibold leading-snug">
                    Entrevue : rencontrez Vicky de La Belle Excuse
                  </p>
                  <p className="text-xs uppercase text-medium-gray">Entrevues</p>
                </div>
              </div>
            </Link2>

            <Link2 href="/mag-mtaregion/developper-sa-creativite-grace-a-lart-lecriture-et-la-poterie">
              <div className="relative mx-2 mb-4 min-h-[250px] max-w-[300px] overflow-hidden rounded-xl text-left shadow-lg">
                <img
                  src={`/wp-content/uploads/2022/10/frankie-cordoba-fPYJeMmYWM4-unsplash-scaled.jpg`}
                  className="w-full"
                />
                <div className="absolute bottom-0 min-h-[80px] w-full bg-white p-3">
                  <p className="mb-2 truncate text-lg font-semibold leading-snug">
                    Développer sa créativité grâce à l’art, l’écriture et la poterie
                  </p>
                  <p className="text-xs uppercase text-medium-gray">Entrevues, Tour du Québec</p>
                </div>
              </div>
            </Link2>

            <Link2 href={`/mag-mtaregion/une-viree-automnale-dans-les-cantons-de-lest-avec-annick-beauchemin`}>
              <div className="relative mx-2 mb-4 min-h-[250px] max-w-[300px] overflow-hidden rounded-xl text-left shadow-lg">
                <img src={`/wp-content/uploads/2022/10/Gorge-Coaticook-3.jpg`} className="w-full" alt="" />
                <div className="absolute bottom-0 min-h-[80px] w-full bg-white p-3">
                  <p className="mb-2 truncate text-lg font-semibold leading-snug">
                    Une virée automnale dans les Cantons de l’Est avec Annick Beauchemin
                  </p>
                  <p className="text-xs uppercase text-medium-gray">Cantons-de-l'Est, Escapades</p>
                </div>
              </div>
            </Link2>
          </SideScroller>

          <Link2 href="/mag-mtaregion">
            <Button2>Consultez le magazine M ta région</Button2>
          </Link2>
        </Section>
      </div>
    </div>
  );
};

const WordRotation = ({ className = '', words = [] }: { words: string[]; className?: string }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    new Promise((r) => setTimeout(r, 2000)).then(() => {
      setCurrentWordIndex((currentWordIndex + 1) % words.length);
    });
  }, [currentWordIndex]);

  return (
    <p className={classNames('font-bold', className)}>
      Pss: ton abonnement rentabilisé en seulement
      <br />
      <span className="animate-fade-in-top text-red" key={currentWordIndex}>
        {words[currentWordIndex]}
      </span>
    </p>
  );
};

export const ProfitableWordRotation = () => {
  return (
    <WordRotation
      className="text-2xl"
      words={['1 spa', '2 restos', '2 microbrasseries', '1 hébergement', '2 parcs régionaux']}
    />
  );
};
